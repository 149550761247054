<template>
  <div>
    <div class="title">AIshipgo x DHgate账号关联</div>
    <div class="text">
      已为您自动创建AIshipgo账号，后续您可通过 DHgate账号登录AIshipgo。
    </div>
    <div class="mian">
      <div class="middle">
        <img src="../../assets/img/login/loading.png" alt="" />
        <div>自动创建 AIshipgo ...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',

  beforeCreate() {
    console.log(this.$route.query, 'this.$route.query');
    if (this.$route.query.firstRelate === 'false') {
      this.$router.push('/');
    }
  },
  created() {
    const { token, firstRelate, isAdministrator } = this.$route.query;
    if (!!token) {
      localStorage.setItem('tokenKey', token);
      localStorage.setItem('isAdministrator', isAdministrator);
      if (firstRelate === 'true') {
        setTimeout(() => {
          this.$message({
            message: '关联成功',
            type: 'success',
          });
          this.$router.push('/');
        }, 3000);
      } else {
        this.$router.push('/');
      }
    } else {
      this.$router.push('/');
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0px;
  color: #6c38e0;
  margin-bottom: 4px;
}
.text {
  width: 100%;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #1d2129;
}
.mian {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.middle {
  display: flex;
  align-content: space-around;
  flex-direction: column;
  align-items: center;
  img {
    animation: rotate 3s linear infinite;
    margin-bottom: 6px;
  }
  div {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0px;

    color: #1d2129;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
@media screen and (max-width: 1280px) {
  .title {
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0px;
    color: #6c38e0;
    margin-bottom: 4px;
  }
  .text {
    font-size: 12px;
    color: #1d2129;
  }
}
</style>
